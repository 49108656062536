/** @jsx jsx */
import { jsx, Grid, Box } from 'theme-ui'
import { FC } from 'react'
import { CollectionTemplateType, ProductItemComponentType, ProductItemType } from '~/@types/models'
import CollectionLayout from '~/components/layout/collection-layout'
import CollectionProducts from '~/containers/collection-products'
import { space } from '~/gatsby-plugin-theme-ui'
import ProductItem from '~/components/product-item'
import { ImageSize } from '~/components/product-item/types'

type Props = CollectionTemplateType

const CollectionFurniture: FC<Props> = ({ pageContext: { data } }) => {
  return (
    <CollectionLayout
      title={data.title}
      description={data.description}
      handle={data.handle}
      ankleContent={data.metafields?.ankleContent}
      heroHeader={{
        isExternal: true,
        ...(data.imageResponsive && {
          imageSet: data.imageResponsive,
        }),
      }}
    >
      <CollectionProducts
        collectionHandle={data.handle}
        initialData={data.products as ProductItemType[]}
        pagination={data.pagination}
        data={data}
        gridVariant="layout.template-furniture"
      />
    </CollectionLayout>
  )
}

export default CollectionFurniture
